import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../layout/Layout';
import SEO from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import ImageCreation from '../../components/Image/ImageCreation/ImageCreation'


const Creations = ({ data }) => {

  const [slice, setSlice] = useState(3);
  const creationRef = useRef();

  const creationLength = data?.allContentfulCreationItem?.nodes.length;
  
  useEffect(() => {
    window.addEventListener("scroll", updateSlice);
    return () => {
      window.removeEventListener("scroll", updateSlice);
    }
  })

  const updateSlice = () => {
    if(window.scrollY + window.innerHeight > creationRef?.current?.offsetHeight) {
      if(creationLength > slice){
        setSlice(slice + 3);
      }
    }
  }
  

  return (
    <Layout>
      <SEO
        lang="fr"
        title='Creations - Publicis et Nous'
        description='Publicis et Nous'
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container" className=" creations col-lg-9 col-md-12">
          <div className="project-box" ref={creationRef}>
            {
              data?.allContentfulCreationItem?.nodes?.slice(0, slice).map((creationItem, index) => (
                <div className={`content`} key={index}>
                  <Link to={'/creations/'+creationItem.slug.replaceAll('_', '-')}>
                    <ImageCreation img={creationItem.visuelThumbnail?.gatsbyImageData} alt={creationItem.creationName}/>
                  </Link>
                  <div className="content-creations col-lg-12">
                    <div className="info">
                      <Link to={'/creations/'+creationItem.slug.replaceAll('_', '-')}>{creationItem.creationDescription}</Link>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulCreationItem(sort: {fields: order} filter: {showCreation: {ne: false}}) {
      nodes {
        slug
        creationName
        creationDescription
        visuelThumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        videoThumbnail {
          gatsbyImageData
        }
      }
    }
  }
`

export default Creations;
